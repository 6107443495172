import React from 'react';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Layout from '../components/Layout';

import Card from '../components/Card';
import OsmMap from '../components/OsmMap';
import Video from '../components/Video';
import ReactPlayer from 'react-player';

import picPlaquette from '../assets/images/event/plaquette.png';
import picPresse1 from '../assets/images/event/presse1.jpg';
import picPresse2 from '../assets/images/event/presse2.jpg';
import pic1 from '../assets/images/event/slideshow/1.jpg';
import pic2 from '../assets/images/event/slideshow/2.jpg';
import pic3 from '../assets/images/event/slideshow/3.jpg';
import pic4 from '../assets/images/event/slideshow/4.jpg';
import pic5 from '../assets/images/event/slideshow/5.jpg';
import pic6 from '../assets/images/event/slideshow/6.jpg';
import pic7 from '../assets/images/event/slideshow/7.jpg';
import pic8 from '../assets/images/event/slideshow/8.jpg';
import pic9 from '../assets/images/event/slideshow/9.jpg';
import pic10 from '../assets/images/event/slideshow/10.jpg';
import pic11 from '../assets/images/event/slideshow/11.jpg';
import pic12 from '../assets/images/event/slideshow/12.jpg';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Les concerts</h2>
          <p>Des scènes en musique à part</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Les créations</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue <b><a href="/artistes#concerts">les artistes</a></b>. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>
          <h3 className="major">À venir</h3>

          <section className="features">

            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            <Card type='agenda' path="/concert" title="Big Fish" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            

          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
